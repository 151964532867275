<template>
    <div>
        <b-row>
      <b-col md="6">
       
            <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      :variant="selectedButton === 'departman' ? 'primary' : 'outline-primary'"
      @click="selectedButton = 'departman'"
    >
      Proje Raporları
    </b-button>
</b-col>
<b-col md="6">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      disabled
      block
      :variant="selectedButton === 'proje' ? 'primary' : 'outline-primary'"
      @click="selectedButton = 'proje'"
    >
      Genel Raporlar
    </b-button>
      
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4"> 
       
        <b-col
          cols="12"
          md="4"
          class="text-center"
          v-if="projectList.length>0"
         
        >
        <h4>Lütfen Proje Seçiniz</h4> 
          <b-form-group
            label="Proje listesi"
            label-for="user-role"
            v-if="!isLoading"
          >
            <v-select
          
              v-model="selectedProject"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="projectList"
              
              :clearable="false"
                label="label"
                placeholder="Projeler"
              :reduce="value"
              input-id="user-role"
            />
          </b-form-group>
         
            <b-form-group >

              <flat-pickr v-model="startDate" class="form-control mt-1" :placeholder="startDateText"
                :config="{ dateFormat: 'Y-m-d', locale: 'tr' }" />
            </b-form-group>
            <b-form-group >

<flat-pickr v-model="endDate" class="form-control mt-1" :placeholder="endDateText"
  :config="{ dateFormat: 'Y-m-d',locale: 'tr' }" />
</b-form-group>
       
         
    
  <button  v-if="selectedProject !=null && startDate !=null && endDate !=null && !isLoading" type="button" @click="getReport" class="btn btn-primary">Görüntüle</button>

<button v-else v-if="isLoading" type="button" class="btn btn-primary disabled">Görüntüle</button>

        </b-col>
    </b-row>
   <hr>
   
    <div class="container mt-4" v-if="report != null"  >
    <h2>{{ report.projectName }}</h2>
    <p>Tarih aralığı: <b> {{ report.description }}</b></p>
    <p>Toplam çalışma süresi: <b> {{ this.formatWorkTime(report.sumWorkTime) }}</b></p>

    
    <b-table striped hover :items="report.workers" :fields="fields" id="report-table" >
      <template v-slot:cell(nameSurname)="row">
        {{ row.value }}
      </template>
      <template v-slot:cell(departmentName)="row">
        {{ row.value }}
      </template>
      <template v-slot:cell(workTime)="row">
        {{ formatWorkTime(row.item.workTime) }}
      </template>
    </b-table>

    <div class="mt-4 text-center">
      <b-button variant="primary mr-2" @click="exportToPDF">PDF olarak indir</b-button>
      <b-button variant="success" @click="exportToExcel">Excel olarak indir</b-button>
    </div>
  </div>
  <div class="d-flex justify-content-center mb-1" variant="primary" v-if="isLoading">
      <strong class="text-center ">Yükleniyor...</strong>
      <b-spinner class="m-auto" variant="primary" />
    </div>
  <!-- {{ report }} -->
    </div>
    
  </template>
  
  <script>
  import { BButton, BCol, BRow ,BFormGroup,BForm,BTooltip,BTable,BSpinner} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { Api } from '@/helpers/Api/apiConfig'
  import flatPickr from 'vue-flatpickr-component'
  import { tr } from "flatpickr/dist/l10n/tr.js";

  import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

  export default {
    components: {
      BButton,
      BCol,
      BRow,  vSelect,BFormGroup,BForm,flatPickr,BTooltip,tr,BTable,BSpinner
    },
    data(){
        return{
            projectList:[{label:'a',value:12},{label:'b',value:1}],
            projects:[],
            selectedProject:null,
            selectedButton: 'departman',
              ///DATEPİCKER ////
        date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      startDateText: 'Başlangıç Tarihi Seçiniz',
      endDateText: 'Bitis Tarihi Seçiniz',
      startDate: null,
      endDate: null,
      isLoading: false,
        
      /////
      ////reportpage
      fields: [
        { key: 'nameSurname', label: 'Name' },
        { key: 'departmentName', label: 'Department' },
        { key: 'workTime', label: 'Work Time' }
      ],
      report: null
    
    }
},
computed: {
    formattedWorkers() {
      // Döngü ile her işçinin iş süresini formatlayın
      return this.data.workers.map(worker => {
        return {
          ...worker,
          workTime: this.formatWorkTime(worker.workTime)
        };
      });
    }
  },

mounted(){
this.getProjects()
},
methods:{
    formatWorkTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours} saat ${minutes} dk ${remainingSeconds} sn`;
    },

    exportToPDF() {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4'
  });

  doc.setFontSize(18);
  doc.text('Proje Raporu', 105, 15, { align: 'center' });

  doc.setFontSize(12);
  doc.text(`Proje Adi: ${this.convertToAscii(this.report.projectName)}`, 20, 30, { 'maxWidth': 170 });
  doc.text(`Rapor Tarih Araligi: ${this.convertToAscii(this.report.description)}`, 20, 40, { 'maxWidth': 170 });
  doc.text(`Toplam Calisma Suresi: ${this.formatWorkTime(this.report.sumWorkTime)}`, 20, 50, { 'maxWidth': 170 });
  doc.setFontSize(12);
  doc.text(`Olusturulma Tarihi: ${new Date().toLocaleDateString('tr-TR')}`, 140, 15, { 'maxWidth': 170 });

  const tableData = this.report.workers.map(worker => [
    this.convertToAscii(worker.nameSurname),
    this.convertToAscii(worker.departmentName),
    this.formatWorkTime(worker.workTime)
  ]);

  doc.autoTable({
    startY: 70,
    head: [['Calisan Adi', 'Departman', 'Calisma Suresi']],
    body: tableData
  });

  doc.save(`${new Date().toLocaleDateString()}-${this.convertToAscii(this.report.projectName)}.pdf`);
},

convertToAscii(text) {
  const charMap = {
    'ğ': 'g', 'Ğ': 'G',
    'ü': 'u', 'Ü': 'U',
    'ş': 's', 'Ş': 'S',
    'ı': 'i', 'İ': 'I',
    'ö': 'o', 'Ö': 'O',
    'ç': 'c', 'Ç': 'C'
  };

  return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, function(match) {
    return charMap[match];
  });
},




    exportToExcel() {
  const workbook = XLSX.utils.book_new();

  // Proje bilgilerini içeren bir çalışma sayfası oluştur
  const projectSheetData = [
    ['Project Name', this.report.projectName],
    ['Description', this.report.description],
    ['Total Work Time', this.formatWorkTime(this.report.sumWorkTime)],
    ['Report Date', new Date().toLocaleDateString()]
  ];
  const projectSheet = XLSX.utils.aoa_to_sheet(projectSheetData);

  // İşçi bilgilerini içeren bir çalışma sayfası oluştur
  const workersData = [['Name', 'Department', 'Work Time']];
  this.report.workers.forEach(worker => {
    workersData.push([worker.nameSurname, worker.departmentName, this.formatWorkTime(worker.workTime)]);
  });
  const workersSheet = XLSX.utils.aoa_to_sheet(workersData);

  // Hücre genişliklerini ayarlamak için özel konfigürasyon
  const worksheetOptions = {
    '!cols': [
      { wpx: 200 }, // Name column width in pixels
      { wpx: 200 }, // Department column width in pixels
      { wpx: 200 }  // Work Time column width in pixels
    ]
  };

  // Çalışma sayfalarını çalışma kitabına ekleme
  XLSX.utils.book_append_sheet(workbook, projectSheet, 'Project Info', worksheetOptions);
  XLSX.utils.book_append_sheet(workbook, workersSheet, 'Workers', worksheetOptions);

  // Excel dosyasını indir
  XLSX.writeFile(workbook, `${new Date().toLocaleDateString()+'-'+this.report.projectName}.xlsx`);
},
    async getReport(){
        this.isLoading = true;
    await Api("GET", `Report/GetReport?projectId=${this.selectedProject.value}&startDate=${this.startDate}&endDate=${this.endDate}`).then((res) => {
        this.report = res.data.data;
  
    //   this.projectList = this.projects.map((item) => {
    //     return {
    //       label: item.name,
          
    //       value: item.projectId,
         
    //       //isActive:false
    //     };
    //   });
     
      console.log( 'report',this.report);
      this.isLoading = false;
    });
    },
    async getProjects() {
    this.isLoading = true;
    await Api("GET", "Report/GetAllActiveProjects").then((res) => {
        this.projects = res.data.data;
    //  this.departments = res.data.data.activeDepartments;
      this.projectList = this.projects.map((item) => {
        return {
          label: item.projectName,
          
          value: item.projectId,
         
          //isActive:false
        };
      });
      // this.departmentList.unshift({
      //   text: 'Tüm Departmanlar',
      //   value: null,
     
      // }) 
      // this.$emit('departmentList',this.departmentList)

      //this.totalRows = this.departmentList.length
      console.log( 'userrdepartments',this.projects);
      this.isLoading = false;
    });
  },
},
    directives: {
      Ripple,
    },
  }
  </script>
   <style lang="scss">
   @import '@core/scss/vue/libs/vue-select.scss';
   @import '@core/scss/vue/libs/vue-flatpicker.scss';
   </style>